import React, { useEffect, useState } from "react";
import { Axios } from "../../../../api/api";
import { toast } from "react-toastify";
import { errorMessages } from "../../../../helpers/error-messages";
import { useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";

const sortArrowSvg = (direction) => (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        {direction === 'desc' ?
            <path
                d="M7.00023 5.17266L11.9502 0.222656L13.3642 1.63666L7.00023 8.00066L0.63623 1.63666L2.05023 0.222656L7.00023 5.17266Z"
                fill="#09121F"
            />
            : <path d="M7 2L11.95 6.05L10.536 7.464L7 3.928L3.464 7.464L2.05 6.05L7 2Z" fill="#09121F"/>
        }
    </svg>
);

export default function PendingOrders() {
    const [orders, setOrders] = useState([]);
    const [pending, setPending] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'arrive_date', direction: 'desc' });
    const [totalGrams, setTotalGrams] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [oneGramPrice, setOneGramPrice] = useState();
    const t = useTranslation();
    const navigation = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(7);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [location, setLocation] = useState(''); // New state for location
    const [carat, setCarat] = useState('');
    const [markets, setMarkets] = useState([]);

    const sortFixing = (data) => {
        const { key, direction } = sortConfig;
        return [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        }).filter(v => pending ? v.status === 0 : true);
    };

    const getOrders = (page) => {
        setLoading(true);
        Axios.get(`preorder/orders/pending`, {
            params: {
                page,
                limit: itemsPerPage,
                search: searchTerm, // Include search term in the request
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction,
                location,
                carat
            }
        })
            .then(res => {
                setOrders(res.data.orders);
                setTotalPages(res.data.total_pages);  // Ensure your API returns totalPages
                setLoading(false);
            })
            .catch(err => {
                toast(errorMessages(err?.response?.data?.code));
                setLoading(false);
            });
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        getOrders(currentPage);
    }, [currentPage, searchTerm, sortConfig, location, carat]);

    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const response = await Axios.get('/preorder/admin/locations');
                setMarkets(response.data);
            } catch (error) {
                console.error("Error fetching markets:", error);
            }
        };
        fetchMarkets();
    }, []);

    useEffect(() => {
        const { totalGrams, totalPrice } = orders.reduce((acc, item) => {
            acc.totalGrams += item.grams;
            acc.totalPrice += item.price;
            return acc;
        }, { totalGrams: 0, totalPrice: 0 });
        if(carat) {
            setOneGramPrice(totalPrice / totalGrams)
        } else {
            setOneGramPrice(null);
        }

        setTotalGrams(totalGrams);
        setTotalPrice(totalPrice);
    }, [orders]);

    const headers = [
        { key: 'order_number', label: 'Order number' },
        { key: 'created_at', label: 'Order Date' },
        { key: 'firstname', label: 'Firstname' },
        { key: 'carat', label: 'Carat' },
        { key: 'grams', label: 'Grams' },
        { key: 'price', label: 'Amount $' },
        { key: 'location', label: 'Location' },
        { key: 'arrive_date', label: 'Appointment Date' },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPageNumbers = () => {
        let pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button onClick={() => handlePageChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }
        console.log(pageNumbers)
        return pageNumbers;
    };

    return (
        <>
            {loading && <p>Loading...</p>}
            <div className="orders-filter tableWrapper pre-order-table" style={{marginBottom: '20px', marginTop: '20px'}}>
                <div style={{marginBottom: '20px'}}>
                    <label>Search by email</label>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <div style={{marginBottom: '20px'}}>
                    <label>Sort by location</label>
                    <select
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    >
                        <option value="">All Locations</option>
                        {markets && markets?.map(market => (
                            <option value={market.id}>{market.location}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <label>Sort by carat</label>
                    <select
                        value={carat}
                        onChange={(e) => setCarat(e.target.value)}
                    >
                        <option value="">All Carats</option>
                        <option value="999">999 - 24K</option>
                        <option value="995">995 - 24K</option>
                    </select>
                </div>
            </div>
            {orders && orders.length ? (
                <div className='tableWrapper pre-order-table' style={{ marginTop: '30px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(29,56,101,0.1)', paddingBottom: '20px' }}>
                        <h3>Pending Orders</h3>
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Total</th>
                            <th>{totalGrams.toFixed(2)} grams</th>
                            <th>${totalPrice.toFixed(2)}</th>
                            <th>{oneGramPrice}</th>
                            <th></th>
                        </tr>
                        <tr>
                            {headers.map(({ key, label }) => (
                                <th key={key} onClick={() => requestSort(key)} style={{cursor:"pointer"}}>
                                    {label} {sortConfig.key === key && sortArrowSvg(sortConfig.direction)}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {sortFixing(orders).map((item, index) => (
                            <tr key={index.toString()}>
                                <td><span>Code: </span>{item.order_number}</td>
                                <td>
                                    <span>Order Date: </span>{new Date(item.created_at).toLocaleDateString()} {new Date(item.created_at).toLocaleTimeString()}
                                </td>
                                <td><span>Firstname: </span>{item.firstname}</td>
                                <td><span>Carat: </span>{Number(item.carat) === 995 ? '995 - 24K' : '999 - 24K'}</td>
                                <td><span>Grams: </span>{item.grams.toFixed(2)}</td>
                                <td><span>Amount: </span>$ {item.price.toFixed(2)}</td>
                                <td><span>Location: </span>{item.location??'pending'}</td>
                                <td>
                                    <span>Appointment Date: </span> {
                                        item.arrive_date ? (
                                            `${new Date(item.arrive_date).toLocaleDateString()} ${new Date(item.arrive_date).toLocaleTimeString('en-US', {
                                                timeZone: 'UTC',
                                                hour12: false
                                            })}`
                                        ) : 'pending'
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : 'Orders not exist'}
            <nav>
            <ul className="pagination">
                    {renderPageNumbers()}
                </ul>
            </nav>
        </>
    );
}
