import React, {useEffect, useState} from "react";
import {dashboardStyle} from "./dashboard-style";
import ActivityCard from "src/components/activityCard/activityCard";
import {colors} from "src/utils/color";
import DashboardTable from "./dashboardTable/dashboardTable";
import UnderHeader from "src/components/underHeader/underHeader";
import {Axios} from "src/api/api";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import ConfirmationModal from "../../../components/confimationModal/confirmationModal";

function Dashboard() {
    const classes = dashboardStyle()
    const [orders, setOrders] = useState()
    const [totalGrams, setTotalGrams] = useState(0);
    const [activity, setActivity] = useState({})
    const t = useTranslation()
    const [totalSoldGrams, setTotalSoldGrams] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const [isEditing, setIsEditing] = useState(false);
    const [newMaxGoldLimit, setNewMaxGoldLimit] = useState();
    const [isEditingDiscount, setIsEditingDiscount] = useState(false);
    const [globalDiscount, setGlobalDiscount] = useState(0);
    const headers = [
        { key: 'order_number', label: 'Order number' },
        { key: 'created_at', label: 'Order Date' },
        { key: 'firstname', label: 'Firstname' },
        { key: 'carat', label: 'Carat' },
        { key: 'grams', label: 'Grams' },
        { key: 'price', label: 'Amount $' },
        { key: 'location', label: 'Location' },
        { key: 'arrive_date', label: 'Appointment Date' },
    ];

    const handleEditClick = () => {
        setIsEditing(true);
        // setNewMaxGoldLimit(newMaxGoldLimit); // Initialize with current value
    };

    const handleSave = () => {
        Axios.put(`/preorder/update-max-gold-limit`, { quantity: newMaxGoldLimit })
            .then(res => {
                setNewMaxGoldLimit(newMaxGoldLimit);
                setIsEditing(false);
                toast.success(t("dashboard.limit_updated"));
            })
            .catch(err => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    };

    const handleDiscountCancel = () => {
        setIsEditingDiscount(false);
    };

    const handleEditDiscount = () => {
        setIsEditingDiscount(true);
        // setNewMaxGoldLimit(newMaxGoldLimit); // Initialize with current value
    };

    const handleDiscountSave = () => {
        Axios.put(`/preorder/update-discount`, { discount: globalDiscount })
            .then(res => {
                setGlobalDiscount(0);
                setIsEditingDiscount(false);
                toast.success(t("dashboard.limit_updated"));
            })
            .catch(err => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const activityCardData = [
        {
            title: t("dashboard.today_new_orders"),
            quantity: activity?.todayOrdersCount? activity?.todayOrdersCount : 0,
            topColor: colors.grayishBlue
        },
    ]

    useEffect(() => {
        Axios(`preorder/admin/preorders`).then(res => {
            setOrders(res.data.orders)
            setActivity(res.data)
            console.log(res.data.orders)
            console.log(orders)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })

        Axios(`preorder/get-max-gold-limit`).then(res => {
            setNewMaxGoldLimit(res.data.quantity)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    useEffect(() => {
        Axios(`preorder/admin/allorders`).then(res => {
            const totalGrams = res.data.orders.reduce((sum, order) => sum + (order.grams || 0), 0);
            setTotalSoldGrams(totalGrams);
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    useEffect(() => {
        if(orders) {
            const {totalGrams, totalPrice} = orders.reduce((acc, item) => {
                acc.totalGrams += item.grams;
                acc.totalPrice += item.price;
                return acc;
            }, {totalGrams: 0, totalPrice: 0});

            setTotalGrams(totalGrams);
            setTotalPrice(totalPrice);
        }
    }, [orders]);

    return (
        <div className={classes.dashboardContainer}>
            <UnderHeader title={t("header.dashboard")} />
            <div className={classes.activitySection}>
                <div className='space-between-container'>
                    <p className='mainTitle'>{t("dashboard.activity")}</p>
                </div>
                <div className={classes.wrapper}>
                    <ActivityCard activityCardData={activityCardData}/>
                    <div className={classes.limitSection}>
                        <p style={{fontSize: '12px', color: '#7C7C7C'}}>gold max limit(grams)</p>
                        <input
                            type="number"
                            value={newMaxGoldLimit - totalSoldGrams}
                            onChange={(e) => setNewMaxGoldLimit(parseFloat(e.target.value))}
                            className={classes.editableInput}
                            step="0.01"
                            style={{border: 'none', background: 'transparent', fontSize: '32px'}}
                        />
                        <button onClick={handleEditClick} className={classes.editButton} style={{fontSize: '12px'}}>
                            Edit
                        </button>
                    </div>
                    <div className={classes.limitSection}>
                        <p style={{fontSize: '12px', color: '#7C7C7C'}}>Master rate</p>
                        <input
                            type="number"
                            value={globalDiscount}
                            onChange={(e) => setGlobalDiscount(parseFloat(e.target.value))}
                            className={classes.editableInput}
                            step="0.01"
                            style={{border: 'none', background: 'transparent', fontSize: '32px'}}
                        />
                        <button onClick={handleEditDiscount} className={classes.editButton} style={{fontSize: '12px'}}>
                            Edit
                        </button>
                    </div>
                    <div className={classes.limitSection}>
                        <p style={{fontSize: '12px', color: '#7C7C7C'}}>gold max limit(grams)</p>
                        <p>From start:{newMaxGoldLimit}</p>
                        <p>current:{newMaxGoldLimit - totalSoldGrams}</p>
                        <p>sold:{totalSoldGrams}</p>
                    </div>
                </div>
            </div>
            {orders ? (
                <div className='tableWrapper pre-order-table' style={{marginTop: '30px'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid rgba(29,56,101,0.1)',
                        paddingBottom: '20px'
                    }}>
                        <h3>Pending Orders</h3>
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Total</th>
                            <th>{totalGrams.toFixed(2)} grams</th>
                            <th>${totalPrice.toFixed(2)}</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            {headers.map(({ key, label }) => (
                                <th key={key} style={{cursor:"pointer"}}>
                                    {label}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {orders && orders.map((item, index) => (
                            <tr key={index.toString()}>
                                <td><span>Code: </span>{item.order_number}</td>
                                <td>
                                    <span>Order Date: </span>{new Date(item.created_at).toLocaleDateString()} {new Date(item.created_at).toLocaleTimeString()}
                                </td>
                                <td><span>Firstname: </span>{item.firstname}</td>
                                <td><span>Carat: </span>{item.carat === 995 ? '995 - 24K' : '999 - 24K'}</td>
                                <td><span>Grams: </span>{item.grams.toFixed(2)}</td>
                                <td><span>Amount: </span>$ {item.price.toFixed(2)}</td>
                                <td><span>Location: </span>{item.location??'pending'}</td>
                                <td>
                                    <span>Appointment Date: </span> {
                                    item.arrive_date ? (
                                        `${new Date(item.arrive_date).toLocaleDateString()} ${new Date(item.arrive_date).toLocaleTimeString('en-US', {
                                            timeZone: 'UTC',
                                            hour12: false
                                        })}`
                                    ) : 'pending'
                                }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : 'Orders not exist'}
            {isEditing && (
                <ConfirmationModal
                    isOpen={isEditing}
                    onConfirm={handleSave}
                    onCancel={handleCancel}
                    title="Confirm Cancelation"
                    message="Are you sure you want to change max available?"
                />
            )}
            {isEditingDiscount && (
                <ConfirmationModal
                    isOpen={isEditingDiscount}
                    onConfirm={handleDiscountSave}
                    onCancel={handleDiscountCancel}
                    title="Confirm Cancelation"
                    message="Are you sure you want to change discount?"
                />
            )}
        </div>
    );
}

export default Dashboard;