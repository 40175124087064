import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import CButton from 'src/cors/button/cButton'; // Adjust import path

interface Group {
    quantity995: number;
    expired_at995: number;
    discount995: number;
    quantity999: number;
    expired_at: number;
    discount: number;
    id: number;
    name: string;
}

interface GroupListProps {
    groups: Group[];
    onEdit: (id: number) => void; // Expect id for editing
    onDelete: (id: number) => void; // Expect id for deletion
}

const GroupList: React.FC<GroupListProps> = ({ groups, onEdit, onDelete }) => {

    return (
        <div className='tableWrapper pre-order-table' style={{marginTop: '15px'}}>
            <div className="cardItem">
                <h1>Groups</h1>
                <Link to={`/groups/create`} style={{maxWidth: '150px'}}>
                    <CButton
                        title="Create New Group"
                        borderWidth={1}
                    />
                </Link>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Discount 999</th>
                    <th>Duration 999</th>
                    <th>Quantity 999</th>
                    <th>Discount 995</th>
                    <th>Duration 995</th>
                    <th>Quantity 995</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {groups.map(group => (
                    <tr key={group.id}>
                        <td><span>Name: </span>{group.name}</td>
                        <td><span>Discount 999 : </span>{group.discount}</td>
                        <td><span>Duration 999 : </span>{group.expired_at==1?24:48}</td>
                        <td><span>Quantity 999 : </span>{group.quantity999}</td>
                        <td><span>Discount 995 : </span>{group.discount995}</td>
                        <td><span>Duration 995 : </span>{group.expired_at995==1?24:48}</td>
                        <td><span>Quantity 995 : </span>{group.quantity995}</td>
                        <td>
                            <Link to={`/groups/edit/${group.id}`}>
                                <CButton
                                    title="Edit"
                                    borderWidth={1}
                                />
                            </Link>
                            <br />
                            <CButton
                                onPress={() => onDelete(group.id)}
                                title="Delete"
                                borderWidth={1}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default GroupList;
